<template>
  <div class="container">
    <Carousel :args="args.slide" />

    <!-- <img class="top-pic" src="/static/img/download/top_pic.png" /> -->
    <!-- <Carousel arrow="never" :args="[{media:'/static/img/download/top_pic.png', media_type:0}]" /> -->

    <div class="title" style="margin-top:80px;">
      <div class="main-title">筒筒APP</div>
      <div class="sub-title">解放校长 轻松管理</div>
    </div>
    <img class="app-pic" src="/static/img/download/app_pic.png">
    <div class="qrcode">
      <div class="title">
        <img src="/static/img/about/mascot/title_bg.png" />
        <div class="text">扫码下载</div>
      </div>
      <div class="content">
        <img :src="args.app_qrcode">
      </div>
    </div>
    <div class="app-img">
      <img class="app-people" src="/static/img/download/app_people.png" />
      <img class="app-bg" src="/static/img/download/app_bg.png" />
    </div>
    <div class="title" style="margin-top:80px;">
      <div class="main-title">筒筒团课</div>
      <div class="sub-title">招生利器 快速裂变</div>
    </div>
    <img class="onlie-course-applet-pic" src="/static/img/download/applet_online_course.png">
    <div class="qrcode" style="padding-bottom:128px;">
      <div class="title">
        <img src="/static/img/about/mascot/title_bg.png" />
        <div class="text">扫码下载</div>
      </div>
      <div class="content">
        <img :src="args.online_course_applet_qrcode">
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
export default {
  components: {
    Carousel,
  },
  data() {
    return {
      args: {},
    };
  },
  created() {
    this.$api("/official/page/download").then(({ data }) => {
      this.args = data;
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top-pic {
    width: 100%;
    height: 740px;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-title {
      color: $font-color-black;
      font-size: 36px;
    }
    .sub-title {
      margin-top: 10px;
      font-size: 14px;
      color: $font-color-gray;
    }
  }

  .app-pic {
    margin-top: 60px;
    width: 887px;
    height: 768px;
  }

  .onlie-course-applet-pic {
    margin-top: 36px;
    width: 924px;
    height: 752px;
  }

  .qrcode {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      width: 299px;
      z-index: 99999;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 299px;
        height: 134px;
      }

      .text {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        margin-left: 30px;

        font-size: 25px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .content {
      position: relative;
      margin-top: -38px;
      z-index: -1;
      width: 250px;
      height: 250px;
      background: #f5f5f5;
      border-radius: 20px;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 182px;
        height: 182px;
        background-color: #fff;
        border-radius: 13px;
        overflow: hidden;
      }
    }
  }

  .app-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: 48px;

    position: relative;

    .app-people {
      width: 1233px;
      height: 768px;
    }
    .app-bg {
      margin-top: 70px;
      left: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      width: 1440px;
      height: 318px;
    }
  }
}
</style>

