<template>
  <div :class="{'carousel-container':true, height:true, 'video-height':video} ">
    <div class="mask" v-if="list.length"></div>
    <video v-if="video" :src="video" muted autoplay loop style="width:100%;height:100%"></video>
    <el-carousel :arrow="arrow" v-if="!video" trigger="click" width="100%" height="100%" indicator-position="none">
      <el-carousel-item v-for="(item,key) in list" :key="key">
        <el-image style="width:100%;" class="height" :src="item.media" fit="fill">
        </el-image>
      </el-carousel-item>
    </el-carousel>
    <div :class="{'down-block':true, 'has-tip':tip}">
      <font v-if="tip" class="tip" @click="scrollDownPage(1)">{{tip}}</font>
      <img class="down" src="/static/img/down.png" @click="scrollDownPage(1)" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: undefined,
      list: [],
    };
  },
  props: {
    arrow: {
      type: String,
      default: () => {
        return "hover";
      },
    },
    args: {
      type: Array,
      default: () => [],
    },
    tip: {
      type: String,
      default: null,
    },
  },
  created() {
    if (this.args.length) {
      this.args.forEach((item) => {
        if (item.media_type == 1) {
          this.video = item.media;
        }
      });
      this.list = this.args;
      console.log(this.list);
    }
  },
  watch: {
    args(items) {
      //判断如果有一个视频则只展示视频
      items.forEach((item) => {
        if (item.media_type == 1) {
          this.video = item.media;
        }
      });
      this.list = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-container {
  position: relative;
  width: 100%;

  .down-block {
    position: absolute;
    top: calc(min(100vh, 740px) - 15px - 45px);
    &.has-tip {
      top: calc(min(100vh, 740px) - 15px - 65px);
    }
    left: 0;
    right: 0;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    animation: jumpUpDown 1.5s infinite;

    .down {
      cursor: pointer;
      width: 25px;
      height: 14px;
      margin-top: 20px;
    }

    .tip {
      cursor: pointer;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
    }
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #351000, $alpha: 0.5);
    z-index: 9999;
  }

  .el-carousel {
    height: 100%;
  }
}

.height {
  height: 740px;
}

.video-height {
  height: 810px;
}

@keyframes jumpUpDown {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>